.container {
  padding: 24px;
  max-width: 1080px;
  margin: 0 auto;
  text-align: center;
}

.billingToggle {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin: 24px 0;
}

.billingButton {
  padding: 12px 32px;
  border: 2px solid #e7e7e7;
  border-radius: 8px;
  background: white;
  cursor: pointer;
  font-size: 16px;
  position: relative;
}

.billingButton.active {
  border-color: #4a4a4a;
  background: #4a4a4a;
  color: white;
}

.savingsBadge {
  position: absolute;
  top: -16px;
  right: -32px;
  background: #ff4e4e;
  color: white;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  opacity: 0.9;
}

.plansGrid {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  margin-top: 24px;
  padding: 0 16px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.planCard {
  flex: 0 1 calc(33.333% - 24px);
  max-width: calc(33.333% - 24px);
  background: white;
  border: 1px solid #e7e7e7;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.planCard:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.planCard h3 {
  margin: 0 0 16px;
  font-size: 24px;
  text-align: center;
}

.planOptions {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.planOption {
  padding: 12px;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
  text-align: center;
  background-color: white;
}

.planOption:hover {
  background-color: #f8f8f8;
}

.planOption.selected {
  background-color: #f0f0f0;
  border-color: #666;
}

.planDescription {
  color: #666;
  font-size: 14px;
  margin: 12px 0;
  min-height: 48px;
  line-height: 20px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.planPrice {
  font-size: 24px;
  font-weight: bold;
  color: #4a4a4a;
  margin: 16px 0;
}

.pricingInfo {
  text-align: center;
  margin: 24px 0;
}

.price {
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 4px;
}

.amount {
  font-size: 32px;
  font-weight: bold;
}

.period {
  color: #666;
}

.savings {
  color: #ff4e4e;
  font-size: 14px;
  margin-top: 8px;
}

.subscribeButton {
  width: 100%;
  padding: 12px;
  background: #4a4a4a;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  transition: background 0.2s;
  max-width: 200px;
  margin: 0 auto;
}

.subscribeButton:hover {
  background: #333;
}

@media (max-width: 767px) {
  .subscribeButton {
    max-width: 160px;
    font-size: 14px;
    padding: 10px;
  }

  .billingButton {
    padding: 10px 16px;
    font-size: 14px;
  }

  .planCard {
    flex: 0 1 100%;
    max-width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .planCard {
    flex: 0 1 calc(50% - 24px);
    max-width: calc(50% - 24px);
  }
}

@media (min-width: 768px) {
  .container {
    padding: 24px;
  }

  .plansGrid {
    grid-template-columns: repeat(2, minmax(auto, 400px));
    justify-content: center;
  }
}

@media (min-width: 1024px) {
  .plansGrid {
    grid-template-columns: repeat(3, minmax(auto, 350px));
    justify-content: center;
  }
}
